import React from "react"
import Testimonials from "../components/testimonials"
import SEO from "../components/seo"

const TestimonialsPage = () => (
  <>
    <SEO title="Testimonials" />
    <div className="hero-section in-pages-bg">
      <div className="cover-overlay"></div>
      <div className="overlay-text">
        <h3 className="is-white font-size-42 font-weight-bold">Testimonials</h3>
        <p className="is-white font-size-20">See what other patients have to say about us</p>
      </div>
    </div>
    <Testimonials />
  </>
)

export default TestimonialsPage
